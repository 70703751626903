import React, { useEffect, useRef, useState } from "react";
import "../Components Styles/Heading.css";
import UpperTagLine from "./UpperTagLine";
import Logo from "../Images/gharesNewLogo4.png";
// import Logo from "../Images/MainLogo5.png";
// import { GoSearch } from "react-icons/go";
// import img1 from "../Images/Caraousal_Images/Trending1.jpg";
// import { IoCloseSharp, IoHeartOutline } from "react-icons/io5";
// import { GrLocation } from "react-icons/gr";
// import { CiUser } from "react-icons/ci";
import {
  BsHandbag,
  BsThreeDotsVertical,
  BsPersonCircle,
  BsCart3,
} from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";
import { useSelector } from "react-redux";
import { GoSearch } from "react-icons/go";
// rgba(251,225,183,255) beige
// rgba(26,76,44,255) Dark Green
// rgba(241,179,29,255) Dark Yellow
import { IoCloseSharp, IoHeartOutline } from "react-icons/io5";
export default function Heading() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const allGoldCategoriesList = [
    "Coin",
    // "Kadas",
    "Mangalsutra",
    "Fancy Chain",
    "Lakhi Mala",
    // "Earrings",
    // "Bangles",
    // "Rings",
    // "Kada",
    // "Mangalsutra",
    // "Pendants",
    // "Chain",
    // "Specially Designed",
  ];
  const allSilverCategoryList = [
    "Anklet",
    "Bracelet",
    "Chain",
    "Coin",
    "Earrings",
    "Ganpati Ornaments",
    "Kada",
    "Mangalsutra",
    "Pendant Set",
    "Rings",
    "Bracelet Chain Set",
    "Studs / Tops",
    "Baby Jewellery",
    "Utensil",
    // "Couple Rings",
    // "Fancy Pendant + Chain",
  ];
  const bagItems = useSelector((state) => state);
  // console.log(bagItems);
  const uniqueItem = bagItems.reducer2;
  const userDetails = bagItems.reducer4;
  const totalItems = bagItems.reducer2.length;
  const navigate = useNavigate();
  useEffect(() => {
    const bodyElement = document.body;
    if (mobileMenu) {
      bodyElement.classList.add("no-scroll");
    } else {
      bodyElement.classList.remove("no-scroll");
    }
  }, [mobileMenu]);

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const [searchItem, setSearchItem] = useState("");
  const [inputColor, setInputColor] = useState("");
  const searchNow = (e) => {
    e.preventDefault();
    if (searchItem.trim() !== "") {
      setPopup(false);
      navigate(`/product/${searchItem}`);
    } else {
      setInputColor("red");
    }
  };
  const [popUp, setPopup] = useState(false);
  const handleMouseEnter = () => {
    setPopup(true);
  };
  let firstNameInitial = null;
  let lastNameInitial = null;
  let initials = "";
  if (userDetails === null || userDetails.length === 0) {
    null;
  } else {
    const firstName = userDetails[0]?.firstName || "";
    const lastName = userDetails[0]?.lastName || "";

    firstNameInitial = firstName ? firstName[0].toUpperCase() : "";
    lastNameInitial = lastName ? lastName[0].toUpperCase() : "";
    initials = `${firstNameInitial}${lastNameInitial}`;
  }

  return (
    <div className="headingMainContainer">
      <div className="headingUpperTagLineBox">
        <UpperTagLine />
      </div>

      <div className="logoBar">
        <Link to="/">
          {/* <div id="mainLogo"></div> */}
          <span>
            <img
              // style={{ width: "auto" }}
              id="mainLogo"
              src={Logo}
              alt="M K Ghare logo"
              onClick={() => console.log("Takeme to Home")}
            />
          </span>
        </Link>

        {/* Mobile View Hamburgr */}
        <div className="hamburger" onClick={() => setMobileMenu(!mobileMenu)}>
          <BsThreeDotsVertical size={"18px"} className="hamburgerIcon" />
          {/* <BsThreeDotsVertical size={"1.2rem"} className="hamburgerIcon" /> */}
        </div>
        <div className="mobileIcons">
          {userDetails === null || userDetails.length === 0 ? (
            <div className="signIn">
              <BsPersonCircle
                size={"18px"}
                color="#1a4c2d"
                onClick={() => navigate("/customerloginpage")}
              />
              {/* <span className="link" style={{ paddingLeft: "0.5rem" }}>
                <Link className="headingSignInOption" to="/customerloginpage">
                  SIGN IN
                </Link>
              </span> */}
            </div>
          ) : (
            <div className="signIn">
              <BsPersonCircle size={"18px"} />
              <span className="link" style={{ padding: "2px" }}>
                {/* <Link to="/accountdetails">SIGN IN</Link> */}
                <Link
                  style={{ padding: "2px", fontSize: "12px" }}
                  to={`/customerprofile?customerId=${userDetails[0]?.id}`}
                >
                  {/* {`${userDetails[0]?.firstName} ${userDetails[0]?.lastName}`} */}
                  {initials}
                </Link>
              </span>
            </div>
          )}

          {/* <BsPersonCircle size={"1.7rem"} /> */}
          {/* <IoHeartOutline size={"1.7rem"} /> */}
          <h3 style={{ marginInline: "2px", fontSize: "18px" }}>
            {totalItems}
          </h3>
          <Link to="/checkoutmain">
            <BsCart3 id="handBagIconMobile" size={"18px"} color="#1a4c2d" />
          </Link>
          {/* <div className="mobileSearchBarMainBox">
            <input type="text" placeholder="Search for any Product..." />
            <GoSearch className="mobileSearchIcon" size={"1rem"} />
          </div> */}
        </div>
        <div
          className="searchBtn searchBtnMobile"
          onClick={() => {
            setInputColor("black"), setSearchItem(""), handleMouseEnter();
          }}
          style={{ cursor: "pointer" }}
        >
          <GoSearch color="#1a4c2d" style={{ marginRight: "20px" }} />
        </div>
        {/* </div> */}
        {popUp === true ? (
          // <form onSubmit={searchNow}>
          <form
            onSubmit={searchNow}
            className="searchBarBoxMain"
            data-aos="fade-up"
          >
            <AiOutlineClose
              style={{ top: "50px", right: "50px" }}
              className="searchBarBoxCloseBtn"
              size={"3rem"}
              onClick={() => setPopup(false)}
            />
            <h1
              style={
                inputColor !== "red"
                  ? { color: "white", margin: "2rem", fontSize: "16px" }
                  : { color: "red", margin: "2rem", fontSize: "16px" }
              }
            >
              Search Any Product Here...
            </h1>
            <input
              style={{
                borderColor: `${inputColor}`,
                width: "80%",
                fontSize: "16px",
                height: "20px",
              }}
              type="text"
              placeholder={"Enter Here"}
              value={searchItem}
              onChange={(e) => {
                setSearchItem(e.target.value);
              }}
            />

            <button
              style={{ fontSize: "16px", height: "30px" }}
              type="submit"
              // onClick={searchNow}
            >
              Search
            </button>
          </form>
        ) : // </form>
        null}
      </div>
      {/* Mobile View List  */}
      <div
        className={
          mobileMenu === false ? "mobileMenuList hide" : "mobileMenuList"
        }
      >
        <MobileNavbar
          mobileMenu={handleMobileMenu}
          goldList={allGoldCategoriesList.map((item, index) => ({
            name: item,
            key: index,
          }))}
          silverList={allSilverCategoryList.map((item, index) => ({
            name: item,
            key: index,
          }))}
        />
        {/* <button
          className="closeBtn"
          onClick={() => {
            setMobileMenu(!mobileMenu);
          }}
        >
          <AiOutlineClose color="white" />
        </button> */}
        {/* <div className="drawerScreenMain"> */}
        <div
          className={
            mobileMenu === false ? "drawerScreen hide" : "drawerScreen"
          }
          onClick={() => setMobileMenu(!mobileMenu)}
        ></div>
        {/* </div> */}
      </div>
      {/* NavBar has 3 components in flex row searchAndStore, links and favAndBag */}
      {/* <DesktopNavbar
        goldList={allGoldCategoriesList}
        silverList={allSilverCategoryList}
      /> */}
      <DesktopNavbar
        goldList={allGoldCategoriesList.map((item, index) => ({
          name: item,
          key: index,
        }))}
        silverList={allSilverCategoryList.map((item, index) => ({
          name: item,
          key: index,
        }))}
      />
    </div>
  );
}
