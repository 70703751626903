import React, { useState, useEffect } from "react";
import "../Components Styles/DesktopNavbar.css";
import { GoSearch } from "react-icons/go";
import { IoCloseSharp, IoHeartOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GrLocation } from "react-icons/gr";
import { CiUser, CiLocationOn } from "react-icons/ci";
import { BsHandbag } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { a17, a3, a62, s1, s3 } from "../Api/RootApiPath";
import { removeFromCart, userLoggedIn } from "../../redux/action/Actions";

export default function DesktopNavbar({ goldList, silverList }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [searchItem, setSearchItem] = useState("");
  const [inputColor, setInputColor] = useState("");
  const bagItems = useSelector((state) => state);

  const uniqueItem = bagItems.reducer2;
  const userDetails = bagItems.reducer4;
  const totalItems = bagItems.reducer2.length;
  const handleRemoveFromBag = (index, productId) => {
    handleDeleteCartItem(index, productId);
  };

  const handleDeleteCartItem = async (index, productId) => {
    const formData = {
      Customer_Id: userDetails[0].id,
      ProductId: productId,
      Status: "Active",
    };

    console.log(formData, "formData");
    console.log(formData, "formData");
    try {
      const response = await fetch(a62, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([formData]),
      });
      const data = await response.json();
      console.log(data, "2nd hit remove data");
      console.log(data, "2nd hit remove data");
      dispatch(removeFromCart(index));
    } catch (error) {
      // if (response.status === 500) {
      // console.log(response.status);
      // console.log(response.statusText);
      // }
      console.error(error);
      // alert(error.message);
    }
  };

  let totalPrice = 0;
  const navigate = useNavigate();
  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };
  let orderId = "";
  const location = useLocation();
  let params = "";
  params = new URLSearchParams(location.search);
  let Customer_id = "";
  Customer_id = params.get("customerId");
  console.log(Customer_id);
  const accountDetails = async () => {
    const formData = {
      Id: Customer_id,
    };

    try {
      const response = await fetch(a3, {
        method: "POST",
        // body: formData,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log("Data:", data);
      loggedIn([data.data]);
      // loggedOut(userDetails);
      setLoading(false);
      // userDetails = data.data[0];
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      navigate("/customerloginpage");
      alert(error);
    }
  };
  useEffect(() => {
    if (Customer_id !== null) {
      accountDetails();
    }
  }, [Customer_id]);

  const [popUp, setPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleMouseEnter = () => {
    setPopup(true);
  };
  const handleMouseLeave = () => {
    setPopup(false);
  };
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const [cartItems, setCartItems] = useState([]);

  const searchNow = () => {
    fbq("track", "Search");
    if (searchItem !== "") {
      setPopup(false);
      navigate(`/product/${searchItem}`);
    } else {
      setInputColor("red");
    }
  };

  return (
    <div className="navbarMain">
      <div className="navbar">
        <div className="searchAndStoreBox">
          <div className="searchAndStoreItem" style={{ marginLeft: "2rem" }}>
            <GoSearch style={{ marginInline: "0.5rem" }} spacing="2rem" />
            <div
              className="searchBtn"
              onClick={() => {
                setInputColor("black"), setSearchItem(""), handleMouseEnter();
              }}
              style={{ cursor: "pointer" }}
            >
              SEARCH
            </div>
          </div>
          {popUp === true ? (
            // <form onSubmit={searchNow}>
            <div className="searchBarBoxMain" data-aos="fade-up">
              <AiOutlineClose
                className="searchBarBoxCloseBtn"
                size={"4rem"}
                onClick={() => setPopup(false)}
              />
              <h1
                style={
                  inputColor !== "red"
                    ? { color: "white", margin: "2rem" }
                    : { color: "red", margin: "2rem" }
                }
              >
                Search Any Product Here...
              </h1>
              <input
                style={{ borderColor: `${inputColor}` }}
                type="text"
                placeholder={"Enter Here"}
                value={searchItem}
                onChange={(e) => setSearchItem(e.target.value)}
              />

              <button onClick={searchNow}>Search</button>
            </div>
          ) : // </form>
          null}
          <div className="searchAndStoreItem">
            <CiLocationOn size={"1.2rem"} />
            <Link to="/stores">
              <div style={{ marginLeft: "0.5rem" }}>STORES</div>
            </Link>
          </div>
        </div>
        <div className="links">
          <li className="dropdown">
            <div className="link" onClick={() => navigate("/material/gold")}>
              GOLD
            </div>
            <div className="dropdown-content">
              <div className="dropdown-box">
                <div className="firstBox">
                  <div
                    style={{ marginLeft: "0rem", padding: "0rem" }}
                    className="allCategoryHead"
                  >
                    <h3>Shop By category</h3>
                  </div>
                  <div className="allCategoryItems">
                    <ul>
                      <Link to="/metal/gold/coin">
                        <li style={{ listStyle: "none" }}>Coins</li>
                      </Link>
                      <Link to="/metal/gold/mangalsutra">
                        <li style={{ listStyle: "none" }}>Mangalsutra</li>
                      </Link>
                      <Link to="/gender/female/chain/gold">
                        <li style={{ listStyle: "none" }}>Fancy Chain</li>
                      </Link>
                      <Link to="/metal/gold/Lakhi mala">
                        <li style={{ listStyle: "none" }}>Lakhi Mala</li>
                      </Link>
                      {/* <li style={{ listStyle: "none" }}>Kadas</li> */}
                    </ul>
                  </div>
                </div>
                <div className="featured">
                  <h2>Gold Coins</h2>
                  <div className="featuredProductImage">
                    <div className="featuredProductOffer">
                      <Link to="/category/coin">
                        <button className="shopNowBtn">SHOP NOW</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="dropdown">
            <div className="link" onClick={() => navigate("/material/silver")}>
              SILVER
            </div>
            <div className="dropdown-content">
              <div className="dropdown-box">
                <div className="firstBox">
                  <div
                    style={{ marginLeft: "0rem", padding: "0rem" }}
                    className="allCategoryHead"
                  >
                    <h3>Shop By category</h3>
                  </div>
                  <div className="allCategoryItems otherBullet">
                    <ul>
                      {silverList.map((x, y) =>
                        x.name === "Utensil" ? (
                          <li style={{ listStyle: "none" }} key={y}>
                            <Link
                              to={`/metal/silver/${encodeURIComponent(x.name)}`}
                            >
                              {x.name}
                            </Link>
                          </li>
                        ) : x.name === "Studs / Tops" ? (
                          <li style={{ listStyle: "none" }} key={y}>
                            <Link
                              to={`/metal/silver/${encodeURIComponent(x.name)}`}
                            >
                              {x.name}
                            </Link>
                          </li>
                        ) : x.name === "Rings" ? (
                          <li style={{ listStyle: "none" }} key={y}>
                            <Link to={`/metal/silver/ring`}>{x.name}</Link>
                          </li>
                        ) : x.name === "Bracelet Chain Set" ? (
                          <li style={{ listStyle: "none" }} key={y}>
                            <Link
                              to={`/gender/male/${encodeURIComponent(
                                "Chain / Bracelet"
                              )}/silver`}
                            >
                              {x.name}
                            </Link>
                          </li>
                        ) : x.name === "Mangalsutra" ? (
                          <li style={{ listStyle: "none" }} key={y}>
                            <Link to={`/gender/female/mangalsutra/silver`}>
                              {x.name}
                            </Link>
                          </li>
                        ) : x.name == "Ganpati Ornaments" ? (
                          <li style={{ listStyle: "none" }} key={y}>
                            <Link to={`/itemType/${x.name}/silver`}>
                              {x.name}
                            </Link>
                          </li>
                        ) : x.name === "Coin" ? (
                          <li style={{ listStyle: "none" }} key={y}>
                            <Link to={`/metal/silver/${x.name}`}>{x.name}</Link>
                          </li>
                        ) : (
                          <li style={{ listStyle: "none" }} key={y}>
                            <Link to={`/itemType/${x.name}/silver`}>
                              {x.name}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div className="featured">
                  {/* <h2>Starlight Edit </h2> */}
                  <h2>Oxidized Products </h2>
                  <div className="featuredProductImage2">
                    <div className="featuredProductOffer">
                      <Link to="/oxidizedproducts">
                        <button className="shopNowBtn">SHOP NOW</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          {/* <li className="dropdown">
            <div className="link" onClick={() => navigate("/material/silver")}>
              STARLIGHT EDIT
            </div>
            <div className="dropdown-content">
              <div className="dropdown-box">
              
                <div style={{ borderLeft: "none" }} className="featured">
                  <h2>Starlight Edit</h2>
                  <div className="featuredProductImage2">
                    <div className="featuredProductOffer">
                      <Link to="/edition/Starlight Edit">
                        <button className="shopNowBtn">SHOP NOW</button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="featured">
                  <h2>Gold Coins</h2>
                  <div className="featuredProductImage">
                    <div className="featuredProductOffer">
                      <Link to="/category/coin">
                        <button className="shopNowBtn">SHOP NOW</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li> */}
          {/* <li style={{ fontWeight: "bold" }} className="link">
            <Link to="/category/coin">DIWALI SPECIAL </Link>
          </li> */}
          {/* <li className="link">
            <Link to="/specialoccasion/Gudi Padwa Special">
              GUDI PADWA SPECIAL
            </Link>
          </li> */}
          <li className="link">
            <Link to="/gender/female/mangalsutra/silver">
              GANESH SILVER
            </Link>
            {/* <Link to="/limitededition/Limited Edition">LIMITED EDITION</Link> */}
            {/* <Link to="/specialoccasion/akshaytritiya">AKSHAY TRITIYA</Link> */}
          </li>
          <li className="link">
            <Link to="/shoppage">WORLD OF GHARE'S</Link>
          </li>

          <li className="link">
            <Link to="/category/gift">GIFTS</Link>
          </li>
        </div>
        <div className="favAndBag">
          {userDetails.length === 0 ? (
            <div className="signIn">
              <CiUser size={"1rem"} />
              <span className="link" style={{ paddingLeft: "0.5rem" }}>
                <Link to="/customerloginpage">SIGN IN</Link>
              </span>
            </div>
          ) : (
            <div className="signIn">
              <CiUser size={"1rem"} />
              <span className="link" style={{ paddingLeft: "0.5rem" }}>
                {/* {Customer_id === null || loading ? ( */}
                <Link to={`/customerprofile?customerId=${userDetails[0].id}`}>
                  {`${userDetails[0].firstName} ${userDetails[0].lastName}`}
                </Link>
                {/* ) : (
                  // <Link to={`/customerprofile?customerId=${Customer_id}`}>
                  //   {`${userDetails[0].firstName} ${userDetails[0].lastName}`}
                  // </Link>
                  <div className="signIn">
                    <CiUser size={"1rem"} />
                    <span className="link" style={{ paddingLeft: "0.5rem" }}>
                      <Link to="/customerloginpage">SIGN IN</Link>
                    </span>
                  </div>
                )} */}
              </span>
            </div>
          )}
          <div className="bag">
            <div className="dropdown2">
              <div className="dropbtn">
                <BsHandbag className="bagIcon" />

                {totalItems != 0 ? (
                  <div className="bagTotalCount">{totalItems}</div>
                ) : null}
                <span className="link" style={{ paddingLeft: "0.5rem" }}>
                  MY BAG
                </span>
              </div>
              <div className="dropdown-content2">
                <div className="dropdown-box">
                  <div
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                    className="myBagMainBox"
                  >
                    {uniqueItem == "" ? (
                      <h2>Empty</h2>
                    ) : (
                      uniqueItem.map((x, y) => {
                        const productPrice = x.count * x.finalPrice; // calculate price for this product
                        totalPrice += productPrice; // add price to total
                        return (
                          <div>
                            <div className="myBagProducts">
                              <img
                                className="myBagProductImage"
                                src={`${s3}/${x.image}`}
                                alt={"ProductImage"}
                                key={y}
                              />
                              <div
                                style={{ overflowX: "hidden" }}
                                className="myBagProductDetails"
                                onClick={() =>
                                  navigate(`/productpage/${x.Product_id}`)
                                }
                              >
                                <p>{x.title}</p>
                                <p>
                                  {x.count} x ₹{x.price} = ₹{x.price * x.count}
                                </p>
                              </div>
                              <div className="closeMyBagBtn">
                                <IoCloseSharp
                                  style={{ cursor: "pointer" }}
                                  size={"1.5rem"}
                                  onClick={() => handleRemoveFromBag(x, x.id)}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                    {uniqueItem.length != [] ? (
                      <div className="myBagTotalBox">
                        <span>Subtotal :</span>
                        <span>₹{totalPrice.toFixed(2)}</span>
                      </div>
                    ) : null}
                    {uniqueItem.length != [] ? (
                      <div className="myBagBtns">
                        <Link to="/checkoutmain">
                          <button id="viewCartBtn">View Cart</button>
                        </Link>
                        <Link to="/checkoutmain">
                          <button id="checkoutBtn">Checkout</button>
                        </Link>
                      </div>
                    ) : (
                      <div style={{ margin: "1rem" }}>
                        Add Something to cart
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
